<template>
  <b-card title="Faktury (Marzec 2022)">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted" />
      </div>

    </div>

    <!-- echart -->
    <app-echart-line :option-data="option" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BCard,
    AppEchartLine,
  },
  data() {
    return {
      option: {
        xAxisData: ['7/3', '8/3', '9/3', '10/3', '11/3', '12/3', '13/3', '14/3', '15/3', '16/3', '17/3', '18/3', '19/3', '20/3'],
        series: ['1', '4', '21', '19', '25', '22', '80', '90', '20', '15', '16', '10', '14', '10', '30'],
      },
    }
  },
}
</script>
