<template>
  <b-card
    no-body
    class="card-apply-job mb-1"
  >

    <b-card-body>
      <h5 class="apply-job-title">
        Przewidywane wpływy w tym miesiącu
      </h5>

      <!-- payment  -->
      <div class="apply-job-package bg-light-primary rounded">
        <div>
          <sup class="text-body">
            <small>$</small>
          </sup>
          <h2 class="d-inline mr-25">
            9,800
          </h2>
          <sub class="text-body"><small>/ pln</small></sub>
        </div>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
  },
  directives: {
    Ripple,
  },
}
</script>
