<template>
  <b-row>
    <b-col cols="12">
      <echart-line />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EchartLine from './EchartLine.vue'

export default {
  components: {
    BRow,
    BCol,
    EchartLine,

  },
}
</script>
